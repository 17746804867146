exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("./../../../src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-porcentajes-js": () => import("./../../../src/pages/porcentajes.js" /* webpackChunkName: "component---src-pages-porcentajes-js" */),
  "component---src-pages-respuestas-js": () => import("./../../../src/pages/respuestas.js" /* webpackChunkName: "component---src-pages-respuestas-js" */),
  "component---src-templates-a-percent-of-b-js": () => import("./../../../src/templates/APercentOfB.js" /* webpackChunkName: "component---src-templates-a-percent-of-b-js" */)
}

